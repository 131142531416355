﻿.tagcloud {
    text-align: center;

    span {
        color: #585858 !important;
        font-size: 12px !important;
        font-weight: 400;
        line-height: 1;
        text-transform: uppercase;
        display: inline-block;
        margin-right: 1px;
        margin-bottom: 4px;
        padding: 10px 15px;
        border: 1px solid #ddd;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        -webkit-transition: all .15s linear 0s;
        -moz-transition: all .15s linear 0s;
        transition: all .15s linear 0s;
    }

    li {
        color: #585858 !important;
        font-size: 12px !important;
        font-weight: 400;
        line-height: 1;
        text-transform: uppercase;
        display: inline-block;
        margin-right: 1px;
        margin-bottom: 4px;
        padding: 10px 15px;
        border: 1px solid #ddd;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        -webkit-transition: all .15s linear 0s;
        -moz-transition: all .15s linear 0s;
        transition: all .15s linear 0s;
    }
}

ul.tagcloud {
    padding: 0;
}
