﻿/*Effect on project cards*/
#projects {
   .projects-item {
      max-width: 25rem;
      margin-left: auto;
      margin-right: auto;

      .projects-link {
         position: relative;
         display: block;
         margin: 0 auto;

         .projects-hover {
            display: flex;
            position: absolute;
            width: 100%;
            height: 100%;
            background: #172a3694;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: opacity ease-in-out 0.25s;

            .projects-hover-content {
               font-size: 1.25rem;
               color: $primary;
            }
         }

         &:hover {
            .projects-hover {
               opacity: 1;
            }
         }
      }

      .projects-caption {
         .projects-caption-heading {
            font-size: 1.5rem;
            font-family: "Montserrat", -apple-system, BlinkMacSystemFont,
               "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
               "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
               "Noto Color Emoji";
            font-weight: 600;
            margin-bottom: 1rem;
         }

         .projects-caption-subheading {
            font-family: "Droid Serif", -apple-system, BlinkMacSystemFont,
               "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
               "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
               "Noto Color Emoji";
         }
      }
   }
}

.projects-modal {
   .modal-dialog {
      margin: 1rem;
      max-width: 100vw;
   }

   .modal-content {
      padding-top: 6rem;
      padding-bottom: 6rem;
      text-align: center;

      h2 {
         font-size: 3rem;
         line-height: 3rem;
      }

      p.item-intro {
         font-style: italic;
         margin-bottom: 2rem;
         font-family: "Droid Serif", -apple-system, BlinkMacSystemFont,
            "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
            "Noto Color Emoji";
      }

      p {
         margin-bottom: 2rem;
      }

      ul.list-inline {
         margin-bottom: 2rem;
      }

      img {
         margin-bottom: 2rem;
      }
   }

   .close-modal {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      width: 3rem;
      height: 3rem;
      cursor: pointer;
      background-color: transparent;

      &:hover {
         opacity: 0.3;
      }
   }
}

@media (min-width: 992px) {
   #projects {
      .projects-item {
         -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
         transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

         &::after {
            -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
            transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
         }

         &:hover {
            -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
         }
      }
   }
}
