﻿#scroll-to-top {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 99;
    border: none;
    outline: none;
    background-color: #4887b0b8;
    color: #e9f0f3bf;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 10px;
    font-size: 1em;
    transition: all 0.5s ease;

    &:hover {
        background-color: #4887b0;
        color: #e9f0f3;
    }
}
