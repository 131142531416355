﻿#mainNav {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    background-color: #fff;
    transition: background-color 0.2s ease;

    .navbar-brand {
        font-weight: 700;
        color: #212529;
    }

    .navbar-nav {
        .nav-item {
            .nav-link {
                color: #6c757d;
                font-weight: 700;
                font-size: 0.9rem;
                padding: 0.75rem 0;

                &:hover {
                    color: var(--primary);
                }

                &:active {
                    color: var(--primary);
                }
            }

            .nav-link.active {
                color: var(--primary) !important;
            }
        }
    }
}

@media (min-width: 992px) {
    #mainNav {
        box-shadow: none;
        background-color: transparent;

        .navbar-brand {
            color: rgba(255, 255, 255, 0.7);
        }

        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: rgba(255, 255, 255, 0.7);
                    padding: 0 1rem;
                }

                &:last-child {
                    .nav-link {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    #mainNav.navbar-scrolled {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        background-color: #fff;

        .navbar-brand {
            color: #212529;

            &:hover {
                color: var(--primary);
            }
        }

        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: #212529;

                    &:hover {
                        color: var(--primary);
                    }
                }
            }
        }
    }
}
