﻿$year-1-main: #7da0c7;
$year-1-sub: #5b7694;
$year-2-main: #7C505B;
$year-2-sub: #563a41;
$year-3-main: #7dad65;
$year-3-sub: #61864e;
$year-4-main: #4a6160;
$year-4-sub: #354645;

.main-timeline {
    overflow: hidden;
    position: relative;

    &:after {
        content: "";
        display: block;
        width: 100%;
        clear: both;
    }

    &:before {
        content: "";
        display: block;
        width: 100%;
        clear: both;
        content: "";
        width: 3px;
        height: 100%;
        background: #d6d5d5;
        position: absolute;
        top: 30px;
        left: 50%;
    }

    .timeline {
        width: 50%;
        float: left;
        padding-right: 30px;
        position: relative;

        &:nth-child(2n) {
            padding: 0 0 0 30px;

            .timeline-icon {
                right: auto;
                left: -14.5px;
                border-color: $year-2-main;
            }

            .year {
                border-radius: 50px 0 0 50px;
                background: $year-2-main;

                &:before {
                    border-left: none;
                    border-right: 35px solid transparent;
                    left: auto;
                    right: 0;
                    border-top-color: $year-2-sub;
                }
            }

            .timeline-content {
                text-align: left;
                margin: 0 35px 0 45px;
            }

            .toggle-btn {
                background: $year-2-main;

                &:hover {
                    background: $year-2-sub;
                }
            }
        }

        &:nth-child(2) {
            margin-top: 170px;
        }

        &:nth-child(odd) {
            /*margin: -175px 0 0;*/
        }

        &:nth-child(even) {
            margin-bottom: 80px;
        }

        &:first-child {
            margin: 0;
        }

        &:last-child {
            &:nth-child(even) {
                margin: 0;
            }
        }

        &:nth-child(3n) {
            .timeline-icon {
                border-color: $year-3-main;
            }

            .year {
                background: $year-3-main;

                &:before {
                    border-top-color: $year-3-sub;
                }
            }

            .toggle-btn {
                background: $year-3-main;

                &:hover {
                    background: $year-3-sub;
                }
            }
        }

        &:nth-child(4n) {
            margin-top: 170px !important;

            .timeline-icon {
                border-color: $year-4-main;
            }

            .year {
                background: $year-4-main;

                &:before {
                    border-top-color: $year-4-sub;
                }
            }

            .toggle-btn {
                background: $year-4-main;

                &:hover {
                    background: $year-4-sub;
                }
            }
        }
    }

    .timeline-icon {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid $year-1-main;
        position: absolute;
        top: 5.5%;
        right: -17.5px;
    }

    .year {
        display: block;
        padding: 10px;
        margin: 0;
        font-size: 30px;
        color: #fff;
        border-radius: 0 50px 50px 0;
        background: $year-1-main;
        text-align: center;
        position: relative;

        &:before {
            content: "";
            border-top: 35px solid $year-1-sub;
            border-left: 35px solid transparent;
            position: absolute;
            bottom: -35px;
            left: 0;
        }
    }

    .timeline-content {
        padding: 1rem 2rem;
        margin: 0 45px 0 35px;
        background: #f2f2f2;
    }

    .toggle-btn {
        padding: 0rem 3rem;
        background: $year-1-main;

        &:hover {
            background: $year-1-sub;
        }
    }

    .title {
        font-size: 19px;
        font-weight: 700;
        color: #504f54;
        /*margin: 0 0 1em;*/
        margin: 0;
    }

    .company {
        font-size: 16px;
        font-weight: 600;
        color: #504f54;
        margin: 0 0 1em;
    }

    .description {
        font-size: 0.9rem;
        color: #505050;
        margin: 0;

        ul {
            padding-left: 1rem;
        }
    }
}

@media only screen and (max-width:991px) {
    .main-timeline {
        overflow: visible;

        &:before {
            top: 0;
            left: 0;
        }

        .timeline {
            &:nth-child(2) {
                margin: 0;
            }

            &:nth-child(even) {
                margin: 0;
            }

            &:nth-child(odd) {
                margin: 0;
            }

            width: 100%;
            float: none;
            padding: 0 0 0 30px;
            margin-bottom: 20px !important;

            &:last-child {
                margin: 0 !important;
            }

            &:nth-child(2n) {
                .year {
                    border-radius: 20px 20px 0 0;
                }

                .timeline-content {
                    margin: 0;
                }
            }
        }

        .timeline-icon {
            right: auto;
            left: -14.5px;
        }

        .year {
            border-radius: 20px 20px 0 0;

            &:before {
                display: none;
            }
        }

        .timeline-content {
            margin: 0;
            border-radius: 0 0 20px 20px;

            &:nth-child(2n) {
                margin: 0;
                border-radius: 0 0 20px 20px;
            }
        }
    }
}
