﻿.certificate {

    .certificate-icon, .certificate-content {
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
    }

    .certificate-icon {

        img {
            max-width: 5rem;
        }
    }
}
