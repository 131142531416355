﻿@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes blink-caret {
    from, to {
        border-color: transparent;
    }

    50% {
        border-color: orange;
    }
}

header.masthead {
    background: linear-gradient(to bottom, rgba(41, 35, 35, 0.8) 0%, rgba(41, 35, 35, 0.8) 100%), url("/assets/images/bg-masthead-min.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 10rem;
    padding-bottom: calc(10rem - 4.5rem);
    height: 100vh;

    h1 {
        font-size: 2.25rem;
    }
}

.typewriter {
    h1 {
        overflow: hidden;
        border-right: .15em solid orange;
        white-space: nowrap;
        margin: 0 auto;
        animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
    }
}

@media (min-width: 992px) {
    header.masthead {
        height: 100vh;
        min-height: 40rem;
        padding-top: 4.5rem;
        padding-bottom: 0;

        p {
            font-size: 1.15rem;
        }

        h1 {
            font-size: 3rem;
        }
    }
}

@media (min-width: 1200px) {
    header.masthead {
        h1 {
            font-size: 3.5rem;
        }
    }
}